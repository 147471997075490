<template>
  <PopUp
    class="modal--xxs modal--bordered modal--visible"
    bgColor="rgba(0,0,0)"
    :themeLight="false"
    :show="modal"
    :hasCloseIcon="step === 5 ? false : true"
    :canBeClosedOutside="false"
    @hideModal="hideModal"
  >
    <ValidationObserver
      ref="observer"
      tag="div"
      id="scroll-block"
      class="reservation"
      v-slot="{ invalid }"
    >
      <header>
        <h2 v-if="step === 5" class="center">Thank you for RSVP'ing for {{ exhibition.title }}!</h2>
        <h2 v-else>RSVP: {{ exhibition.title }}</h2>
      </header>
      <div>
        <div v-if="step === 1" class="block">
          <p>Which date you are planning to attend?</p>
          <div class="form__row">
            <ValidationProvider
              tag="div"
              class="form__row_col"
              :rules="{ required: true, min: 2, max: 255 }"
              v-slot="{ errors }"
            >
              <BaseSelect
                class="form-control--transparent form-control--auto"
                placeholder="Day"
                :selected="reservation.date"
                :items="dates"
                @input="changeReservationDate"
              ></BaseSelect>
            </ValidationProvider>
          </div>
        </div>
        <div v-if="step === 2" class="block">
          <p>
            Select an available timeslot for
            {{ $moment(reservation.date).format("MMMM Do, YYYY") }}:
          </p>
          <div class="form__row">
            <div class="timeslots">
              <button
                v-for="time in timeslots"
                :key="time.value"
                class="timeslots__item"
                :class="{
                  'timeslots__item--disabled': !time.available,
                  'timeslots__item--selected': time.value === reservation.time,
                }"
                :disabled="!time.available"
                @click="changeTime(time.value)"
              >
                {{ time.value }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="step === 3" class="block">
          <p>
            {{ $moment(reservation.date).format("MMMM Do, YYYY") }} | {{ reservation.time }} EST
          </p>
          <div class="form__row form__row--mb-15">
            <ValidationProvider
              tag="div"
              class="form__row_col form__row_col--side"
              :rules="{ required: true, min: 2, max: 255 }"
              v-slot="{ errors }"
            >
              <BaseInput
                class="form-control--simple"
                placeholder="First Name"
                :required="true"
                :validateError="errors[0]"
                v-model="reservation.first_name"
                :element="reservation.first_name"
                @input="reservation.first_name = $event"
              ></BaseInput>
            </ValidationProvider>
            <ValidationProvider
              tag="div"
              class="form__row_col form__row_col--side"
              :rules="{ required: true, min: 2, max: 255 }"
              v-slot="{ errors }"
            >
              <BaseInput
                class="form-control--simple"
                placeholder="Last Name"
                :required="true"
                :validateError="errors[0]"
                v-model="reservation.last_name"
                :element="reservation.last_name"
                @input="reservation.last_name = $event"
              ></BaseInput>
            </ValidationProvider>
          </div>
          <div class="form__row form__row--mb-15">
            <ValidationProvider
              tag="div"
              class="form__row_col"
              :rules="{ required: true, min: 2, max: 255 }"
              v-slot="{ errors }"
            >
              <BaseInput
                class="form-control--simple"
                placeholder="Email"
                :required="true"
                :validateError="errors[0]"
                v-model="reservation.email"
                :element="reservation.email"
                @input="reservation.email = $event"
              ></BaseInput>
            </ValidationProvider>
          </div>
          <div class="form__row form__row--mb-15">
            <ValidationProvider tag="div" class="form__row_col" >
              <BaseNumberInput
                class="form-control--simple"
                placeholder="Additional Guests"
                v-model="reservation.guests"
                :element="reservation.guests"
                :defaultValue="0"
                @input="reservation.guests = $event"
              ></BaseNumberInput>
            </ValidationProvider>
          </div>
        </div>
        <div v-if="step === 4" class="block">
          <p>
            Timeslot for {{ $moment(reservation.date).format("MMMM Do, YYYY") }}
            {{ reservation.time }}
          </p>
          <ul>
            <li><span>Name: </span>{{ reservation.first_name }} {{ reservation.last_name }}</li>
            <li><span>Email: </span>{{ reservation.email }}</li>
            <li><span>Guests: </span>{{ reservation.guests }}</li>
          </ul>
        </div>
      </div>
      <div class="actions" :class="step < 2 ? 'end' : step === 5 ? 'center' : ''">
        <BaseButton
          v-if="step > 1 && step < 5"
          class="button--xs button--outline-white button--uppercase"
          text="BACK"
          @click="back"
        ></BaseButton>
        <BaseButton
          class="button--xs button--white button--uppercase"
          :text="step === 5 ? 'close' : 'Continue'"
          :loading="changesLoading"
          :disabled="invalid || (step === 2 && !reservation.time)"
          @click="next"
        ></BaseButton>
      </div>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapGetters } from "vuex";

import PopUp from "@/components/PopUp.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseNumberInput from "@/components/inputs/BaseNumberInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";

export default {
  props: {
    exhibition: { type: Object, default: null },
    modal: { type: Boolean, default: false },
  },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    BaseInput,
    BaseNumberInput,
    BaseSelect,
  },
  data() {
    return {
      changed: false,
      step: 1,
      reservation: {
        first_name: "",
        last_name: "",
        email: "",
        guests: "0",
        date: "",
        time: null,
      },
      times: [],
      changesLoading: false,
    };
  },
  created() {
    this.apiGetExhibitionTimeslotDaysAvailability(this.exhibition.id);
  },
  computed: {
    ...mapGetters("publicapi/exhibitions", {
      dates: "getTimeslotDatesAvailability",
      timeslots: "getTimeslotAvailability",
    }),
  },
  methods: {
    ...mapActions("publicapi/exhibitions", [
      "apiGetExhibitionTimeslotDaysAvailability",
      "apiGetExhibitionTimeslotAvailability",
      "apiPostRsvp",
    ]),
    back() {
      this.step = this.step - 1;
    },
    async next() {
      if (this.step === 4) {
        const { success, error } = await this.apiPostRsvp({
          exhibition_slug: this.exhibition.slug,
          data: this.reservation,
        });
        if (success) {
          this.step = this.step + 1;
        } else {
          Vue.toasted.show(error.guests[0], {
            duration: 5000,
            type: "error",
          });
        }
      } else if (this.step === 5) {
        this.$emit("hideModal");
      } else this.step = this.step + 1;
    },
    changeTime(value) {
      this.reservation.time = value;
    },
    async changeReservationDate($event) {
      this.reservation.date = $event.id;
      this.reservation.time = "";
      this.apiGetExhibitionTimeslotAvailability({ id: this.exhibition.id, date: $event.id });
    },
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Continue creation",
        }).then(result => {
          if (!result.value) {
            this.$emit("hideModal");
          }
        });
      } else this.$emit("hideModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.reservation {
  header {
    h2 {
      max-width: 80%;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.14;
      margin-bottom: 32px;
      &.center {
        max-width: 100%;
        text-align: center;
      }
    }
  }
  .block {
    & > p {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.14;
      margin-bottom: 12px;
    }
    ul {
      li {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.14;
        margin-bottom: 4px;
        span {
          font-weight: 700;
        }
      }
    }
    &.center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    &.end {
      justify-content: flex-end;
    }
    &.center {
      justify-content: center;
    }
  }
}
.timeslots {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #898989;
    @include type($fs: 14px, $lh: 1.25, $fw: 600);
    color: $white;
    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &--selected,
    &:not(:disabled):hover {
      border-color: #fff;
      background: #fff;
      color: #111111;
    }
  }
}
</style>

